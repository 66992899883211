<template>
  <v-container>
    <NatureOfDisposalList/>
  </v-container>
</template>

<script>
import NatureOfDisposalList from "@/components/app_setting/nature_of_disposal/NatureOfDisposalList";

export default {
  name: "ExecutionTypePage",
  components: {
    NatureOfDisposalList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Case Filed By'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>